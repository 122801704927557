
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';



import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'As good as our people | Styria Media Group',
      keywords: [''],
      description: 'As good as our people.',
      image: require('@/assets/img/og/karriere.png'),
      
    },
    en: {
      title: 'As good as our people | Styria Media Group',
      keywords: [''],
      description: 'As good as our people.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,

    
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
